import React, { useRef } from "react"
import classnames from "classnames/bind"
import Img from "gatsby-image/withIEPolyfill"
import gsap from "gsap"
// Component
import Social from "../../layouts/social"
import TextReveal from "../../components/text-reveal"
import Divider from "../../components/divider"

// Layout
import Header, { HeaderLinkItem } from "../../layouts/header"
import LayoutWrapper from "../../layouts/wrapper"
import { MainContainer } from "../../layouts/container"
import Footer from "../../layouts/footer"

// Data
import useAboutpageData from "../../hook/aboutpage-data"

// Style
import listStyle from "../../style/components/list.module.scss"
import buttonStyle from "../../style/components/button.module.scss"
import experienceStyle from "../../style/components/about/experience-block.module.scss"

const Test = props => {
  const {
    experience_title,
    experience_group,
    awards_group,
    awards_title,
    selected_project_group,
    selected_project_title,
  } = useAboutpageData()

  const HomeExperienceData = {
    heading: "sneak peek",
    awards: awards_title.text,
    awards_group: awards_group,
    experience: experience_title.text,
    experience_group: experience_group,
  }

  return pug`
    LayoutWrapper.bg-dark.overflow-hidden
      Header(link3=true)
      Social
      MainContainer
        section#HomepageExpr.page-section.relative(className=classnames(props.className))
          div.flex.flex-wrap.content-center#HomepageExpr.pt-20(className=listStyle.list_wrapper)
            div.mb-10
              h2.text-theme-100=HomeExperienceData.experience
            div.col-12.flex.flex-row(className=experienceStyle.wrapper)
              div.flex.items-center.justify-center.overflow-hidden.bg-primary-main(className=classnames(experienceStyle.image_wrapper))
                Img(fluid=experience_group[2].icon.fluid style={ height:'100%', width:'100%' })
              div.flex.flex-col.items-center.justify-between(className=classnames(experienceStyle.text_wrapper,"md:flex-row"))
                div
                  h5.text-theme-100.capitalize(className=experienceStyle.text_name)=experience_group[1].name.text
                div.flex-grow-0.flex-shrink-0.text-right
                  span.block.text-theme-100.font-semibold.uppercase.mb-1(className=experienceStyle.text_title)=experience_group[2].title.text
                  span.block.text-theme-300.mb-2(className=experienceStyle.text_date)=experience_group[0].date_and_local.text
            div(className=experienceStyle.text_divider)

            div.flex.justify-center.w-full.mt-16
              HeaderLinkItem(to="/about")
                div(className=classnames(buttonStyle.btn, buttonStyle.btn_lg, buttonStyle.btnShadow_primary, buttonStyle.btnArrowRight_white))
                  span Discover More
      Footer


  `
}

export default Test
