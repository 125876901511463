import React, { useEffect, useRef } from "react"
import classnames from "classnames/bind"
import gsap, { SteppedEase } from "gsap/gsap-core"
import TextPlugin from "gsap/TextPlugin"
import Splitting from "splitting"

// style
import LandingStyle from "../style/components/landing.module.scss"

const TextReveal = React.forwardRef((props, ref) => {
  const textItemRef = useRef(null)
  const textCursorRef = useRef(null)

  useEffect(() => {
    /**
     * Text typewriter effect
     * reference: https://codepen.io/grinmorg/pen/yLJvERd
     * */
    const textItem = textItemRef.current
    const textCursor = textCursorRef.current
    const textArray = [
      "interaction design.",
      "product design.",
      "human behavior.",
      "website design.",
      "brand design.",
    ]

    gsap.fromTo(
      textCursor,
      { autoAlpha: 0, x: 2 },
      { autoAlpha: 1, duration: 0.5, repeat: -1, ease: SteppedEase.config(1) }
    )

    const masterTl = gsap.timeline({
      repeat: -1,
      delay: 1.5,
    })

    for (let i = 0; i < textArray.length; i++) {
      const tl = gsap.timeline({
        repeat: 1,
        repeatDelay: 1.5,
        ease: "Linear.easeNone",
        yoyo: true,
      })
      tl.to(textItem, {
        duration: 3,
        text: {
          value: textArray[i],
        },
      })
      masterTl.add(tl)
    }

    return () => {}
  }, [])

  return pug`
    div.textReveal.relative.overflow-hidden(className=props.className ref=ref)
      p#spiltText(className=classnames(LandingStyle.text_subtitle,props.darkmode?"text-theme-100":"text-gray-100"))
        | ${`A `}
        strong UIUX designer
        | ${` with `}
        strong an engineering background
        | ${` who specializes in user experience and `}
        span.textItem(ref=textItemRef)
        span.textCursor.inline-block.m-0(className=props.darkmode?"text-theme-300":"text-gray-300" ref=textCursorRef) _
  `
})

export default TextReveal
