import { useStaticQuery, graphql } from "gatsby"

const useAboutpageData = () => {
  const { aboutpageData } = useStaticQuery(
    graphql`
      query {
        aboutpageData: prismicAboutPage {
          data {
            awards_group {
              date_and_local {
                text
                raw
                html
              }
              name {
                raw
                text
                html
              }
              title {
                raw
                text
                html
              }
            }
            awards_title {
              text
              raw
              html
            }
            body {
              ... on PrismicAboutPageBodyOneColumn {
                id
                slice_type
                items {
                  content {
                    raw
                    text
                    html
                  }
                  title {
                    raw
                    text
                    html
                  }
                }
                primary {
                  heading {
                    raw
                    text
                    html
                  }
                  num {
                    raw
                    text
                    html
                  }
                }
              }
              ... on PrismicAboutPageBodyTwoColumn {
                id
                items {
                  content {
                    raw
                    text
                    html
                  }
                  title {
                    raw
                    text
                    html
                  }
                }
                primary {
                  heading {
                    raw
                    text
                    html
                  }
                  image {
                    fluid {
                      ...GatsbyPrismicImageFluid
                    }
                    alt
                  }
                }
                slice_type
              }
              ... on PrismicAboutPageBodyFullImage {
                id
                items {
                  full_image {
                    fluid {
                      ...GatsbyPrismicImageFluid
                    }
                    alt
                  }
                }
                slice_type
              }
              ... on PrismicAboutPageBodyImage {
                id
                items {
                  image {
                    fluid {
                      ...GatsbyPrismicImageFluid
                    }
                    alt
                  }
                }
                slice_type
              }
              ... on PrismicAboutPageBodyQuote {
                id
                primary {
                  quote {
                    raw
                    text
                    html
                  }
                }
                slice_type
              }
            }
            body1 {
              ... on PrismicAboutPageBody1OneColumn {
                id
                slice_type
                items {
                  content {
                    raw
                    text
                    html
                  }
                  title {
                    raw
                    text
                    html
                  }
                }
                primary {
                  heading {
                    raw
                    text
                    html
                  }
                  num {
                    raw
                    text
                    html
                  }
                }
              }
              ... on PrismicAboutPageBody1TwoColumn {
                id
                items {
                  content {
                    raw
                    text
                    html
                  }
                  title {
                    raw
                    text
                    html
                  }
                }
                primary {
                  heading {
                    raw
                    text
                    html
                  }
                  image {
                    fluid {
                      ...GatsbyPrismicImageFluid
                    }
                    alt
                  }
                }
                slice_type
              }
              ... on PrismicAboutPageBody1FullImage {
                id
                items {
                  full_image {
                    fluid {
                      ...GatsbyPrismicImageFluid
                    }
                    alt
                  }
                }
                slice_type
              }
              ... on PrismicAboutPageBody1Image {
                id
                items {
                  image {
                    fluid {
                      ...GatsbyPrismicImageFluid
                    }
                    alt
                  }
                }
                slice_type
              }
              ... on PrismicAboutPageBody1Quote {
                id
                primary {
                  quote {
                    raw
                    text
                    html
                  }
                }
                slice_type
              }
            }
            enjoy_image {
              enjoy_image1 {
                fluid(maxWidth: 1000) {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                }
                alt
              }
            }
            experience_group {
              date_and_local {
                raw
                text
                html
              }
              name {
                raw
                text
                html
              }
              title {
                raw
                text
                html
              }
              content {
                html
                raw
                text
              }
              icon {
                fluid {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                }
              }
            }
            experience_title {
              raw
              text
              html
            }
            life_photo {
              life_photo_1 {
                fluid(maxWidth: 1000) {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                }
                alt
              }
            }
            name {
              raw
              text
              html
            }
            awards_intro {
              html
              raw
              text
            }
            overview {
              html
              raw
              text
            }
            selected_project_group {
              content {
                html
                raw
                text
              }
              date_and_local {
                html
                raw
                text
              }
              name1 {
                html
                raw
                text
              }
              title {
                html
                raw
                text
              }
            }
            selected_project_title {
              html
              raw
              text
            }
            experience_intro {
              html
              raw
              text
            }
          }
        }
      }
    `
  )
  return aboutpageData.data
}

export default useAboutpageData
